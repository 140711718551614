/**
 * Import Dependency
 */

/**
 * Import API
 */

import { CHARGINGPOINT_CATEGORY } from '@/../shared/valueholders/chargingpoint-statuses'
import { Bugfender } from '@bugfender/sdk'
import { checkStatus, returnJson, fetchCompressedData } from '@/helpers/api'
import Vue from 'vue'
import _ from 'lodash'

/**
 * Find propper import source property to use in locationSources filtering
 */
const getValidImportSource = ({ chargingpoint }) => {
  ['meta', 'address'].forEach(prop => {
    if (!chargingpoint.data[prop].source.toLowerCase().includes('import') && !chargingpoint.data[prop].source.startsWith('Eco')) {
      chargingpoint.data[prop].source = null
    }
  })
  return chargingpoint
}

const getValidAddressSource = ({ source, chargingpoint }) => {
  const addressSource = chargingpoint.data.address?.source
  return (/evtools/i.test(source) || /import|^EcoMovement/i.test(addressSource)) && source.includes(addressSource)
}

const state = {
  active: false,
  selectedChargingPointRefId: null,

  /**
   * Phases, that are added here, are locked and need to be unlocked by holding or extra clicking
   */
  lockedPhases: [],

  /**
   * helper alternative locations
   */
  rejectedLocationUuid: null,
  isPlanningAlternativeLocation: false,
  // for all locations that have been prompted
  flaggedLocations: new Set(),

  /**
   * All lines that should be displayed on the map.
   *
   * Lines are configurable
   *
   * @param {array} fromCoordinates
   * @param {array} toPoints
   * @param {boolean} [dashedLine] - false
   */
  mapLines: [],

  /**
   * All charging points
   */
  chargingpoints: [],
  initialChargingpoints: [],
  chargingPointsByValidator: [],
  /**
   * There is a layer per charging point type
   */
  layers: null,

  /*
  * All comments (used for search and filters)
  */
  allComments: [],

  /*
  * Comments by chargingpoint uuid (used for validation count indicator)
  */
  comments: [],
  hasLoadedChargingPoints: false,
  /*
  * All participation comments
  */
  participationComments: [],
  // ids of the selected parking lots
  selectedParkingLotIds: [],
  // ids of the results of the search
  highlightedParkingLots: [],
  locationSources: [
    { type: 'EcoMovement', label: 'Eco Movement', visibility: true, count: 0  },
    { type: 'import-royal-haskoning', label: 'Royal HaskoningDHV', visibility: true, count: 0 },
    { type: 'import-vattenfall', label: 'Vattenfall', visibility: true, count: 0 },
    { type: 'import-mrae', label: 'MRA-E', visibility: true, count: 0 },
    { type: 'import-buch-gemeenten', label: 'BUCH gemeenten', visibility: true, count: 0 },
    { type: ['import-overmorgen', 'SPOL Import'], label: 'Import SPOL', visibility: true, count: 0 },
    { type: 'import-lpn', label: 'Import Laadpaal Nodig', visibility: true, count: 0 },
    { type: 'import-den-bosch', label: 'Gemeente Den Bosch', visibility: true, count: 0 },
    { type: 'import-amsterdam', label: 'Import Amsterdam', visibility: true, count: 0 },
    { type: ['Google', 'Manual', 'evtools'], label: 'EV Maps', visibility: true, count: 0 },
    { type: 'workflow', label: 'Workflow', visibility: false, count: 0, path: 'properties.isWorkflowCreated' },
    { type: 'participation', label: 'Participatie', visibility: false, count: 0, path: 'participationUuid' },
  ],
}

const getters = {
  isActive: state => !!state.active,
  isChargingpointSelected: state => state.selectedChargingPointRefId !== null,
  getSelectedChargingpointRefId: state => state.selectedChargingPointRefId,
  getSelectedChargingpoint: state => state.chargingpoints.find(chargingpoint => chargingpoint.ref['@ref'].id === state.selectedChargingPointRefId),
  getSelectedChargingpointUuid: (state, getters) => getters['getSelectedChargingpoint']?.data.uuid,
  hasLoadedChargingPoints: (state) => state.hasLoadedChargingPoints,

  getChargingPoints: state => state.chargingpoints.filter(chargingpoint => !chargingpoint.data.deleted_at && !chargingpoint.data.merged_at),
  getInitialChargingPoints: state => state.initialChargingpoints,
  getChargingPointById: state => ({ id }) => state.chargingpoints.find(chargingpoint => chargingpoint.data.properties.id === id),
  getChargingPointByRefId: state => ({ refId }) => state.chargingpoints.find(chargingpoint => chargingpoint.ref['@ref'].id === refId),
  getChargingPointsByValidator: state => state.chargingPointsByValidator,
  getChargingPointByUuid: state => ({ uuid }) => state.chargingpoints.find(chargingpoint => chargingpoint.data.uuid === uuid),
  getLockedPhases: state => state.lockedPhases,
  getPlannedChargingPoints: state => state.chargingpoints.filter(chargingpoint => CHARGINGPOINT_CATEGORY.PLANNED.includes(chargingpoint.data.properties.status)),
  getLocationSources: state => state.locationSources.filter(source => source.count),

  /**
   * Used for the chargingpoint list in PlanModeSearch (search/filter sidebar left) and ChargerPointLayer (map)
   *
   * @return {array} - chargingpoints
   */
  getFilteredChargingpoints: (_, getters, rootState, rootGetters) => {
    if (!rootGetters['filters/getActiveFilters']) {
      return getters['getChargingpoints']
    }

    // prefilter statuses are used to filter the locations, before running the filter functions on them.
    // F.e. monitoring data is only available for realized locations, so we can filter out all other locations.
    const prefilter = rootGetters['filters/getActiveFilters'].flatMap(filter => filter.statuses ?? [])

    return rootGetters['filters/getActiveFilters']
      .reduce(
        // run all filter functions on given chargingpoints
        (chargingpoints, filter) => filter.filterFn({ chargingpoints, filter }),
        // start with only the chargingpoints that are visible on the map
        getters['getChargingPoints']
          .filter(chargingpoint =>
            // filter locations by prefilter, if active
            (prefilter.length > 0 ? prefilter.includes(chargingpoint.data.properties.status) : true) &&
            // only show chargingpoints that are visible on the map
            getters['getPlanmodeLayers']?.find(layer => layer.id === `chargingpoints-${chargingpoint.data.properties.status}`)?.visible),
      )
  },
  getChargingPointIndexByRefId: state => ({ refId }) => {
    return state.chargingpoints.findIndex(chargingpoint => chargingpoint.ref['@ref'].id === refId)
  },
  getSuccessors: (state, getters) => {
    if (! getters['getSelectedChargingpointUuid']) return []

    return getters['getSuccessorsByUuid']({ uuid: getters['getSelectedChargingpointUuid'] })
  },
  getSuccessorsByUuid: (state, getters) => ({ uuid }) => {
    const needle = getters['getChargingPointByUuid']({ uuid })
    if (! needle) return []

    return getters['getChargingPoints']
      .filter(chargingpoint => {
        const { predecessor } = chargingpoint.data.properties

        if (! predecessor) return false
        if (predecessor.uuid !== needle.data.uuid) return false

        return true
      })
  },
  /**
   * get all predecessors in path recursively
   */
  // In previous commit (WIP dashboard) was this comment-block-end removed
   // and therefore the subsequent getters were disabled which caused a popup error (could not be opened)
  getPredecessors: (state, getters) => {
    if (! getters['getSelectedChargingpointUuid']) return []

    const predecessor = getters['getSelectedChargingpoint'].data.properties.predecessor
    if (! predecessor) return []

    return getters['getPredecessorsByUuid']({ uuid: predecessor.uuid })
  },
  getPredecessorsByUuid: (state, getters) => ({ uuid }) => {
    const predecessor = getters['getChargingPointByUuid']({ uuid })

    if (! predecessor) {
      return []
    }

    let more = []
    if (predecessor?.data.properties.predecessor) {
      more = getters['getPredecessorsByUuid']({ uuid: predecessor?.data.properties.predecessor.uuid })
    }

    return [predecessor].concat(more)
  },
  getAllAlternativeLocationsByUuid: (state, getters) => ({ uuid }) => {
    const successors = getters['getSuccessorsByUuid']({ uuid })

    if (! successors.length) return []

    const more = successors.flatMap(successor => getters['getAllAlternativeLocationsByUuid']({ uuid: successor.data.uuid }))

    return successors.concat(more)
  },

  getRejectedLocationUuid: (state) => state.rejectedLocationUuid,
  isPlanningAlternativeLocation: (state) => state.isPlanningAlternativeLocation,
  getFlaggedLocations: (state) => state.flaggedLocations,

  /**
   * compute the lines to all successors, starting at uuid (origin) recursively
   */
  getLinesToSuccessorsRecursiveByUuid: (state, getters) => ({ uuid }) => {
    const chargingpoint = getters['getChargingPointByUuid']({ uuid })
    const successors = getters['getSuccessorsByUuid']({ uuid })

    if (! successors.length) return []

    const more = successors.flatMap(successor => getters['getLinesToSuccessorsRecursiveByUuid']({ uuid: successor.data.uuid }))

    return [{
      fromCoordinates: chargingpoint.data.coordinates,
      toPoints: successors.map(point => point.data.coordinates),
      dashedLine: true,
    }].concat(more)
  },

  /**
   * get all lines that should be drawn to the map
   */
  getMapLines: state => state.mapLines,

  /**
   * Chargingpoint layer state
   */
  getPlanmodeLayers: state => state.layers,

  /**
   * Chargingpoint comments
   */
  getComments: state => state.comments,

  /**
   * All comments for filtering e.g.
   */
  getAllComments: state => state.allComments,

  getCommentIndexByRefId: state => ({ refId }) => state.comments.findIndex(comment => comment.ref['@ref'].id === refId),
  getCommentsByChargingpointUuid: (state) => ({ chargingpointUuid }) => state.comments.filter(comment => comment.data.chargingpointUuid === chargingpointUuid),
  getAllCommentsByCurrentUser: (state, getters, rootState, rootGetters) => state.allComments.filter(comment => comment.data.UserId === rootGetters['currentUser/getCurrentUserId']),
  hasUserAlreadyVoted: (state, getters) => ({ chargingpointUuid }) => getters['getAllCommentsByCurrentUser']
    .filter(comment => comment.data.chargingpointUuid === chargingpointUuid)
    .filter(comment => comment.data.deleted_at === undefined)
    .some(comment => !!comment.data.validation),
  getCommentsBySelectedChargingpoint: (state, getters) => state.comments.filter(comment => comment.data.chargingpointUuid === getters['getSelectedChargingpointUuid']),

  /**
   * Participation comments
   */
  getParticipationComments: state => state.participationComments,
  getParticipationCommentIndexByRefId: state => ({ refId }) => state.participationComments.findIndex(comment => comment.ref['@ref'].id === refId),
  getParticipationCommentsByChargingpointUuid: (state) => ({ chargingpointUuid }) => state.participationComments.filter(({ data }) => data.chargingpointUuid === chargingpointUuid),
  getParticipationCommentsBySelectedChargingpoint: (state, getters) => state.participationComments.filter(comment => comment.data.chargingpointUuid === getters['getSelectedChargingpointUuid']),

  /**
   * Possible duplicates
   */
  getPossibleDuplicatesOfSelectedChargingpoint: (state, getters) => {
    // Get all chargingpoints referenced by the selected chargingpoint
    const selectedChargingpoint = getters['getSelectedChargingpoint']
    const possibleDuplicateOf = selectedChargingpoint.data?.possibleDuplicates?.flatMap(
      (uuid) => getters['getChargingPointByUuid']({ uuid }) ?? [],
    ) ?? []

    // Get all chargingpoints that reference the selected chargingpoint
    const allChargingpoints = getters['getChargingPoints']
    const possibleDuplicatedBy = allChargingpoints.filter(
      (chargingpoint) => chargingpoint.data?.possibleDuplicates?.includes(selectedChargingpoint.data.uuid),
    )

    // Return all collected chargingpoints, filtered by the current visible chargingpoints
    return possibleDuplicateOf.concat(possibleDuplicatedBy)
      .filter(chargingpoint => getters['getFilteredChargingpoints'].findIndex(cp => cp.data.uuid === chargingpoint.data.uuid) !== -1)

  },
  getSelectedParkingLotIds: (state) => state.selectedParkingLotIds,
  getHighlightedParkingLots: (state) => state.highlightedParkingLots,
}
const actions = {
  setLoadingState ({ commit }, { value }) {
    commit('setLoadingState', { value })
  },
  // Sinds aug. 2024, some municipalities with Volt import, need to have locking configurable //
  // If a prop. isVoltLocked is not present within a config object, Volt is locked. //
  // If isVoltLocked is present, than the choice (toggle value) will be used //
  setEditabilityBySource ({ commit }, { isLocked, source }) {
    commit('setEditabilityBySource', { isLocked, source })
  },
  setLocationSourcesVisibilityFromStorage ({ commit }, { locationSources }) {
    commit('setLocationSourcesVisibilityFromStorage', { locationSources })
  },
  setLocationSourcesVisibility ({ commit }, { value }) {
    commit('setLocationSourcesVisibility', { value })
  },
  filterChargingPointsBySource ({ commit }, { value, source, version }) {
    commit('filterChargingPointsBySource', { value, source, version })
  },
  /**
   * Add or update a chargingpoint that was saved to the Data Storage after the initial load event
   *  This may be triggered due to an action by the current user, or by another user through a Pusher event
   */
  addOrUpdateChargingPoint({ commit, getters, rootGetters }, { chargingpoint }) {
    if (! chargingpoint) return

    // Ignore actions that relate to chargingpoints from a municipality that is no longer active
    if (rootGetters['access/getActiveMunicipality'] !== chargingpoint.data.code) return

    let refId = chargingpoint.ref['@ref'].id
    let index = getters.getChargingPointIndexByRefId({ refId })

    if (index !== -1) {
      commit('updateChargingpoint', { chargingpoint, index })
    } else {
      commit('addChargingpoint', { chargingpoint })
    }
  },
  mergeChargingpoints({ commit, dispatch }, { chargingpoints, result }) {
    dispatch('deselectChargingPoint')
    const toBeDeleted = chargingpoints.find(uuid => uuid !== result.data.uuid)
    commit('deleteChargingpoint', { chargingpointUuid: toBeDeleted })
    dispatch('addOrUpdateChargingPoint', { chargingpoint: result })
  },
  removePossibleDuplicates({ dispatch, getters }, { uuid1, uuid2 }) {
    const chargingpoint1 = getters['getChargingPointByUuid']({ uuid: uuid1 })
    chargingpoint1.data.possibleDuplicates = chargingpoint1.data.possibleDuplicates?.filter(uuid => uuid !== uuid2) ?? []
    dispatch('addOrUpdateChargingPoint', { chargingpoint: chargingpoint1 })

    const chargingpoint2 = getters['getChargingPointByUuid']({ uuid: uuid2 })
    chargingpoint2.data.possibleDuplicates = chargingpoint2.data.possibleDuplicates?.filter(uuid => uuid !== uuid1) ?? []
    dispatch('addOrUpdateChargingPoint', { chargingpoint: chargingpoint2 })
  },
  // eslint-disable-next-line no-unused-vars
  async fetchCommentsByChargingpointUuid({ _, dispatch, rootGetters }, { chargingpointUuid }) {
    const code = rootGetters['access/getActiveMunicipality']
    const token = await this.$auth.getTokenSilently()

    return await fetchCompressedData('/api/commentsload', {
      method: 'POST',
      headers: {
        authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({ code, chargingpointUuid }),
    })
      .then(response => {
        if (! response.comments) {
          return
        }

        dispatch('addOrUpdateComments', ({ comments: response.comments }))
      })
      .catch((e) => {
        Bugfender.error('fetchCommentsByChargingpointUuid: ', e)

        Vue.notify({
          type: 'error',
          title: 'Er is iets misgegaan!',
          text: 'De opmerkingen konden niet worden geladen',
        })
      })
  },

  // eslint-disable-next-line no-unused-vars
  async fetchAllComments({ commit }, { code }) {
    const token = await this.$auth.getTokenSilently()

    return await fetchCompressedData('/api/commentsload', {
      method: 'POST',
      headers: {
        authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({ code }),
    })
      .then(response => {
        if (!response.comments) {
          return
        }

        commit('setAllComments', ({ comments: response.comments }))
      })
      .catch((e) => {
        Bugfender.error(e)

        Vue.notify({
          type: 'error',
          title: 'Er is iets misgegaan!',
          text: 'De opmerkingen konden niet worden geladen',
        })
      })
  },

  async fetchChargingPointsByValidator({ commit }, { user_id }) {
    const token = await this.$auth.getTokenSilently()

    try {
      const response = await fetchCompressedData('/api/chargingpoints-by-validator', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({ user_id }),
      })

      if (!response) {
        throw new Error('No response returned')
      }
      commit('setChargingPointsByValidator', { chargingpoints: response.records })
      return response.records
    } catch (error) {
      Bugfender.error(error)
      Vue.notify({
        type: 'error',
        title: 'Er is iets misgegaan!',
        text: 'De laadpalen konden niet worden geladen',
      })
    }
  },
  addOrUpdateComments({ commit }, { comments }) {
    commit('updateCommentsAsNeeded', { comments })
  },

  addOrUpdateComment({ commit, getters }, { comment }) {
    let refId = comment.ref['@ref'].id
    // todo:: this is bad for performance... shouldn't we set it up in an object notation so that we don't have to search in an array but can directly access it via UUID?
    let index = getters.getCommentIndexByRefId({ refId })

    if (index !== -1) {
      commit('updateComment', { comment, index })
    } else {
      commit('addComment', { comment })
    }
  },
  deleteComment({ commit, getters }, { comment }) {
    let refId = comment.ref['@ref'].id
    let index = getters.getCommentIndexByRefId({ refId })
    commit('updateComment', { comment: comment, index })
  },
  async fetchParticipationCommentsByChargingpointUuid({ dispatch }, { code, chargingpointUuid }) {
    const token = await this.$auth.getTokenSilently()

    return await fetch('/api/participationcommentsload', {
      method: 'POST',
      headers: {
        authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({ code, chargingpointUuid }),
    })
      .then(await checkStatus)
      .then(returnJson)
      .then(({ comments }) => {
        if (comments) {
          comments.forEach(comment => dispatch('addOrUpdateParticipationComment', ({ comment })))
        }
      })
      .catch((e) => {
        Bugfender.error('fetchParticipationCommentsByChargingpointUuid: ', e)

        Vue.notify({
          type: 'error',
          title: 'Er is iets misgegaan!',
          text: 'De participatie opmerkingen konden niet worden geladen',
        })
      })
  },
  async fetchAllParticipationComments({ dispatch }, { code }) {
    const token = await this.$auth.getTokenSilently()

    return await fetch('/api/participationcommentsload', {
      method: 'POST',
      headers: {
        authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({ code }),
    })
      .then(await checkStatus)
      .then(returnJson)
      .then(({ comments }) => {
        if (comments) {
          comments.forEach(comment => dispatch('addOrUpdateParticipationComment', ({ comment })))
        }
      })
      .catch((e) => {
        Bugfender.error(e)

        Vue.notify({
          type: 'error',
          title: 'Er is iets misgegaan!',
          text: 'De opmerkingen konden niet worden geladen',
        })
      })
  },
  addOrUpdateParticipationComment({ commit, getters }, { comment }) {
    let refId = comment.ref['@ref'].id
    let index = getters.getParticipationCommentIndexByRefId({ refId })

    if (index !== -1) {
      commit('updateParticipationComment', { comment, index })
    } else {
      commit('addParticipationComment', { comment })
    }
  },

  /**
   * Update the status already with the optimistic response, this will be reverted if something goes wrong while updating
   */
  async updateCommentStatus({ dispatch, rootGetters }, { comment, status }) {
    const oldComment = JSON.parse(JSON.stringify(comment))

    comment.data.status = status
    dispatch('addOrUpdateParticipationComment', ({ comment, where: 'in action' }))

    fetch('/api/participationcommentsave', {
      method: 'POST',
      headers: {
        authorization: `Bearer ${await this.$auth.getTokenSilently()}`,
      },
      body: JSON.stringify({
        code: rootGetters['access/getActiveMunicipality'],
        refId: comment.ref['@ref'].id,
        status,
      }),
    })
      .then(await checkStatus)
      .then(returnJson)
      .catch(() => {
        dispatch('addOrUpdateParticipationComment', ({ comment: oldComment, where: 'recall action' }))

        Vue.notify({
          type: 'error',
          title: 'Niet gelukt!',
          text: 'Er ging iets mis tijdens het opslaan van de comment status.',
        })

        Bugfender.warn(`Could not update the status of comment ${comment.ref['@ref'].id}`)
      })
  },

  selectChargingPoint({ dispatch, commit, getters }, { refId }) {
    commit('setSelectedChargingPoint', { refId })
    dispatch('showLinesToAlternativeLocationsByUuid', ({ uuid: getters['getSelectedChargingpoint'].data.uuid }))
    dispatch('showLinesToPossibleDuplicates') // TODO: distinguish lines from alternative locations
  },
  deselectChargingPoint({ commit, dispatch }) {
    commit('setSelectedChargingPoint', { refId: null })
    dispatch('hideLinesToAlternativeLocationsByUuid')
    dispatch('hideLinesToPossibleDuplicates')
  },

  /*
  * LAYERS
  */
  updatePlanmodeLayer({ commit }, { value, layer }) {
    commit('updatePlanmodeLayerVisibility', { value, id: layer.id })
  },

  /**
   * COMMANDS
   */
  activatePlanmode({ commit }) {
    commit('setActiveState', { active: true })
  },
  planAlternativeLocationForUuid({ commit, dispatch }, { uuid }) {
    commit('setRejectedLocationUuid', { uuid })
    dispatch('showLinesToAlternativeLocationsByUuid', { uuid })
  },
  clearAlternativeLocationForUuid({ commit }) {
    commit('setRejectedLocationUuid', { uuid: null })
  },
  showAlternativeLocations({ commit }) {
    commit('setIsPlanningAlternativeLocation', { active: true })
  },
  hideAlternativeLocations({ commit }) {
    commit('setIsPlanningAlternativeLocation', { active: false })
  },
  showLinesToAlternativeLocationsByUuid({ commit, getters }, { uuid }) {
    if (!uuid) return

    const origin = getters['getPredecessorsByUuid']({ uuid }).slice().pop() ?? getters['getSelectedChargingpoint']
    const lines = getters['getLinesToSuccessorsRecursiveByUuid']({ uuid: origin.data.uuid })
    commit('setMapLinesById', { id: 'alternate-locations', lines })
  },
  hideLinesToAlternativeLocationsByUuid({ commit }) {
    commit('setMapLinesById', { id: 'alternate-locations', lines: [] })
  },
  showLinesToPossibleDuplicates({ commit, getters }) {
    const selectedChargingpoint = getters['getSelectedChargingpoint']
    const possibleDuplicates = getters['getPossibleDuplicatesOfSelectedChargingpoint']

    const lines = [{
      fromCoordinates: selectedChargingpoint.data.coordinates,
      toPoints: possibleDuplicates.map(point => point.data.coordinates),
      dashedLine: true,
    }]
    commit('setMapLinesById', { id: 'possible-duplicates', lines })
  },
  hideLinesToPossibleDuplicates({ commit }) {
    commit('setMapLinesById', { id: 'possible-duplicates', lines: [] })
  },
  addToFlaggedLocations({ commit }, { chargingpoints }) {
    chargingpoints.forEach(chargingpoint => commit('addFlaggedLocation', { uuid: chargingpoint.data.uuid }))
  },
  setParkingLotIds ({ commit }, { ids }) {
    commit('setParkingLotIds', { ids: ids.filter(Boolean) })
  },
  setHighlightedParkingLots ({ commit }, { ids }) {
    commit('setHighlightedParkingLots', { ids: ids.filter(Boolean) })
  },
}
const mutations = {
  setEditabilityBySource (state, { isLocked, source }) {
    state.chargingpoints.map(chargingpoint => {
      if (chargingpoint.data.meta?.source === source) {
        chargingpoint.data.isLockedForEditing = isLocked
      }
    })
  },
  setLocationSourcesVisibilityFromStorage (state, { locationSources }) {
    locationSources.sources.map(source => {
      const index = state.locationSources.findIndex(locationSource => locationSource.type.toString() === source.type.toString())

      if (index !== -1) {
        state.locationSources[index].visibility = source.visibility
      }
    })
  },
  setLocationSourcesVisibility (state, { value }) {
    state.locationSources.map(source => source.visibility = value)
    state.chargingpoints = value ? state.initialChargingpoints : []
  },
  setChargingPointsByValidator(state, { chargingpoints }){
    state.chargingPointsByValidator = chargingpoints
  },
  filterChargingPointsBySource (state, { value, source, version }) {
    const index = state.locationSources.findIndex(locationSource => locationSource.type.toString() === source)
    const path = state.locationSources[index].path
    state.locationSources[index].visibility = value

    const filterBySource = ({ value }) => {
      const chargingpoints = value ? state.initialChargingpoints : state.chargingpoints

      return chargingpoints.filter(cp => {
        const notInWorkflowAndParticipation = !cp.data.participationUuid && !cp.data.properties.isWorkflowCreated
        const isParticipationVisible = cp.data.participationUuid &&
          state.locationSources.some(source => source.type === 'participation' && source.visibility)
        if (path) {
          // Evaluate property path if path is present
          const prop = path.split('.').reduce((a, b) => a[b], cp.data)
          return !!prop === value
        }

        if (!!cp.data.meta?.source && !!cp.data.address?.source) {
          getValidImportSource({ chargingpoint: cp })
        }

        const sourceMatch = ((source.includes(cp.data.meta?.source) && notInWorkflowAndParticipation) || // If locationSource type is an comma separated string (Google, Manual, ...)
                            getValidAddressSource({ chargingpoint: cp, source }) && notInWorkflowAndParticipation)

        return sourceMatch === value || isParticipationVisible // Participation cp's are also EV Maps created
      })
    }

    state.chargingpoints = !value
      ? filterBySource({ value })
      : [...state.chargingpoints, ...filterBySource({ value })]

    const selectedSources = {
      sources: state.locationSources.map(source => ({ type: source.type, visibility: source.visibility })),
      version,
    }
    //console.log(state.chargingpoints.length, 'arr', arr.length)
    localStorage.setItem('evmaps-locationSources-visibility', JSON.stringify(selectedSources))
  },
  setParkingLotIds (state, { ids }) {
    state.selectedParkingLotIds = ids
  },
  setHighlightedParkingLots (state, { ids }) {
    state.highlightedParkingLots = ids
  },
  setActiveState(state, { active }) {
    state.active = !!active
  },
  setIsPlanningAlternativeLocation(state, { active }) {
    state.isPlanningAlternativeLocation = active
  },
  setRejectedLocationUuid(state, { uuid }) {
    state.rejectedLocationUuid = uuid
  },
  setMapLinesById(state, { id, lines }) {
    state.mapLines = state.mapLines.filter(line => line.id !== id)

    lines.forEach(newLine => state.mapLines.push({
      id,
      ...newLine,
    }))
  },
  addFlaggedLocation(state, { uuid }) {
    state.flaggedLocations.add(uuid)
  },
  /**
   * Upon loading the Chargingpoints data layer, or switching municipalities, the chargingpoints are loaded / replaced
   */
  setChargingPoints(state, { chargingpoints }) {
    // todo:: refactor to reduce functions
    let visibleSources = []

    state.locationSources.map(source => {
      if (source.visibility) {
        Array.isArray(source.type)
          ? source.type.forEach(type => visibleSources.push(type))
          : visibleSources.push(source.type)
      }
      source.count = 0
    })

    state.initialChargingpoints = state.chargingpoints = chargingpoints

    // Set counts of charging point sources
    state.chargingpoints.forEach(cp => {
      const CPMetaSource = cp.data.meta?.source !== 'evtools' && cp.data.meta?.source
      const source = cp.data.properties.isWorkflowCreated
        ? 'workflow'
        : cp.data.participationUuid
          ? 'participation'
          : (CPMetaSource || cp.data.address?.source)

      const index = state.locationSources.findIndex(s => s.type.includes(source))

      if (index !== -1) {
        state.locationSources[index].count ++
      }
    })

    state.chargingpoints = chargingpoints.filter(cp => {
      const notInWorkflowAndParticipation = !cp.data.participationUuid && !cp.data.properties.isWorkflowCreated
      if (!!cp.data.meta?.source && !!cp.data.address?.source) {
        getValidImportSource({ chargingpoint: cp })
      }

      return (visibleSources.includes(cp.data.meta?.source || cp.data.address?.source) && notInWorkflowAndParticipation) ||
        (visibleSources.includes('workflow') ? cp.data.properties.isWorkflowCreated : false) ||
        (visibleSources.includes('participation') ? !!cp.data.participationUuid : false)
    })

    state.hasLoadedChargingPoints = true
  },
  setSelectedChargingPoint(state, { refId }) {
    state.selectedChargingPointRefId = refId
  },
  addChargingpoint(state, { chargingpoint }) {
    state.chargingpoints.push(chargingpoint)
  },
  updateChargingpoint(state, { chargingpoint, index }) {
    state.chargingpoints.splice(index, 1, chargingpoint)
  },
  deleteChargingpoint(state, { chargingpointUuid }) {
    state.chargingpoints = state.chargingpoints.filter(
      (chargingpoint) => chargingpoint.data.uuid !== chargingpointUuid,
    )
  },

  addComment(state, { comment }) {
    state.comments.push(comment)
    setTimeout(() => {
      state.allComments.push(comment)
    })
  },
  updateComment(state, { comment, index }) {
    const refId = comment.ref['@ref'].id
    const allCommentsIndex = state.allComments.findIndex(com => com.ref['@ref'].id === refId)

    state.comments.splice(index, 1, comment)
    state.allComments.splice(allCommentsIndex, 1, comment)
  },
  updateCommentsAsNeeded(state, { comments }) {
    // upsert comments to comments per location
    comments.forEach(comment => {
      const index = state.comments.findIndex(c => c.ref['@ref'].id === comment.ref['@ref'].id)
      if (index === -1) {
        state.comments.push(comment)
      } else {
        state.comments.splice(index, 1, comment)
      }
    })

    // make a copy of allComments
    const oldComments = JSON.parse(JSON.stringify(state.allComments))

    // then upsert comments to allComments
    const newComments = comments.reduce((acc, comment) => {
      const index = acc.findIndex(c => c.ref['@ref'].id === comment.ref['@ref'].id)
      if (index === -1) {
        acc.push(comment)
      } else {
        acc.splice(index, 1, comment)
      }

      return acc
    }, state.allComments.slice())

    // only update if there are changes, for performance reasons
    if (! _.isEqual(oldComments, newComments)) {
      state.allComments = newComments
    }
  },
  setAllComments(state, { comments }) {
    state.allComments = comments
  },
  addParticipationComment(state, { comment }) {
    state.participationComments.push(comment)
  },
  updateParticipationComment(state, { comment, index }) {
    state.participationComments.splice(index, 1, comment)
  },

  /**
   * Set the layer details (e.g. which types of charging points are visible)
   */
  setPlanmodeLayers(state, { layers }) {
    state.layers = layers
  },
  updatePlanmodeLayerVisibility(state, { value, id }) {
    const index = state.layers.findIndex(layer => layer.id === id)

    // Don't update the layer if it could not be found
    if (index === -1) {
      return
    }

    state.layers[index].visible = value
    // trigger update
    state.layers = [...state.layers]
  },
  setLoadingState (state, { value }) {
    state.hasLoadedChargingPoints = value
  },
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
