<template>
  <div style="display:none" />
</template>

<script>
import userMixin from '@/mixins/common/userMixin'
import { mapGetters, mapMutations } from 'vuex'
import { StyleConditions } from '@/helpers/mapbox'

export default {
  name: 'MapDataLayers',
  mixins: [userMixin],
  props: {
    /**
     * Indicates whether the MapBox instance is ready
     */
    loaded: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      activeSources: [],
      activeLayers: [],
      active: '',
      defaultLayout: {
        'visibility': 'none',
      },
      pipesLayers: [
        { name: 'riool', color: '#0016a6' },
        { name: 'telecom', color: '#aa01ff' },
        { name: 'ecowater', color: '#01d5ff' },
        { name: 'gasleiding', color: '#ffe101' },
        { name: 'rioolpers', color: '#925500' },
        { name: 'stadsverwarming', color: '#ff0901' },
      ],
      highlightedParkingLots: [],
    }
  },
  computed: {
    ...mapGetters('config', {
      configLayers: 'contextLayers',
    }),
    ...mapGetters('prognose', [
      'labelLayer',
    ]),
    ...mapGetters('deployment', [
      'DeploymentLayerName',
    ]),
    ...mapGetters('access', [
      'canAccessLayer',
    ]),
    ...mapGetters('layers', {
      expectedActiveLayers: 'getExpectedActiveLayers',
    }),
    ...mapGetters('planmode', ['isActive', 'getSelectedParkingLotIds', 'isChargingpointSelected', 'getHighlightedParkingLots']),
  },
  watch: {
    loaded() {
      this.removeLayers()
      this.addLayers()
    },
    /**
     * If the config changed we'll need to update the layer.
     *  For a vector layer this requires removing the current layer first.
     */
    configLayers() {
      this.removeLayers()
      this.addLayers()
    },
    /**
     * Let the application know when all active layers have been loaded (actually only added)
     */
    activeLayers(value) {
      if (this.expectedActiveLayers.length <= value.length) {
        this.setLoaded({
          loaded: true,
        })
      }
    },
    getSelectedParkingLotIds () {
      if (this.$store.map.getLayer('parkinglot')) {
        this.setParkingLotsStyle ()
      }
    },
    getHighlightedParkingLots () {
      if (this.$store.map.getLayer('parkinglot')) {
        this.setParkingLotsStyle ()
      }
    },
  },
  methods: {
    ...mapMutations('prognose', [
      'setLoaded',
    ]),
    /**
     * This function sets the style for parking lots on the map. It defines a color scheme for different types of parking lots
     * and applies these colors based on certain conditions. The conditions include whether the parking lot is selected,
     * whether it is part of the search results, and the type of the parking lot (E8, E10, or others).
     * The function uses the 'case' method of the StyleConditions helper class to build the style conditions.
     * After defining all the conditions, it applies the style to the 'parkinglot' layer on the map.
     */
    setParkingLotsStyle () {
      const color = {
        selected: 'orange',
        search: 'hsla(0,0%,22%,0.7)',
        default: 'hsla(0,0%,22%,0.4)',
        // eType colors
        'E8': 'hsla(0,100%,50%,0.4)',
        'E10': 'hsla(230,100%,21%,0.4)',
        'reserved': 'hsla(196,100%,62%,0.4)',
      }

      // start building the style condition by using the helper class
      // we apply the "case" method, which is basically a switch statement, which stops after the first match
      // https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#case
      const styles = new StyleConditions({ method: 'case' })

      // add conditions for all selected parking lot ids
      this.getSelectedParkingLotIds.forEach(id => styles.addCondition({
        condition: ['==', ['get', 'id'], id],
        output: color.selected,
      }))

      // add condition for all search result parking lot id
      this.getHighlightedParkingLots.forEach(id => styles.addCondition({
        condition: ['==', ['get', 'id'], id],
        output: color.search,
      }))

      // add style condition for special parking lot types:
      // E8 = "opladen elektrische voertuigen"
      // E10 = "blauwe zone"
      const eType = ['E8', 'E10']
      eType.forEach(id => styles.addCondition({
        condition: ['==', ['get', 'eType'], id],
        output: color[id],
      }))

      // add fallback for other, non-regular parking lot types, which are already in use for a different purpose
      styles.addCondition({
        condition: ['>', ['length', ['get', 'eType']], 0],
        output: color.reserved,
      })

      // last, add a default color for everything else
      styles.addDefault({ value: color.default })

      // then apply the style condition to the layer
      this.$store.map.setPaintProperty('parkinglot', 'fill-color', styles.getConditions())
    },
    handleParkinglot (e) {
      if (!this.isActive || !e.features.length) return
        const id = e.features[0].properties.id
        if (this.getSelectedParkingLotIds.length >= 8 && !this.getSelectedParkingLotIds.includes(id)) return


        if (!this.isChargingpointSelected) {
          const selectedParkingLots = new Set([...this.getSelectedParkingLotIds])

          selectedParkingLots.has(id) ? selectedParkingLots.delete(id) : selectedParkingLots.add(id)

          this.$store.dispatch('planmode/setParkingLotIds', { ids: Array.from(selectedParkingLots) })
        }

    },
    addLayers() {
      // Add all pipes layers (Almere e.g)
      this.pipesLayers.forEach(layer => this.addPipesLayer({ layer }))

      // If there are no layers to add, the activelayer watcher won't trigger. So we do it here.
      if (this.expectedActiveLayers.length === 0) {
        this.setLoaded({
          loaded: true,
        })
        return
      }

      // grondeigendom
      if (
        this.isLayerActive({ name: 'grondeigendom_gemeente' }) &&
        this.canAccessLayer({ id: 'grondeigendom_gemeente' })
      ) {
        this.$store.map.addSource('grondeigendom_gemeente', {
          type: 'vector',
          url: this.configLayers.grondeigendom_gemeente.url,
        })
        this.activeSources.push('grondeigendom_gemeente')

        this.$store.map.addLayer({
          'id': 'grondeigendom_gemeente',
          'type': 'fill',
          'source': 'grondeigendom_gemeente',
          'source-layer': this.configLayers.grondeigendom_gemeente.source,
          'minzoom': 12,
          'paint': {
            'fill-color': 'hsla(340,88%,47%,0.2)', // "hsl(52, 73%, 73%)",
            'fill-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              12,
              0,
              13,
              1,
            ],
          },
          'layout': {
            ...this.defaultLayout,
          },
        }, this.labelLayer)
        this.activeLayers.push('grondeigendom_gemeente')
      }

      // deelgebied vlakken with text and hover state
      if (this.isLayerActive({ name: 'deelgebied_vlak' })) {
        this.$store.map.addSource('deelgebied_vlak', {
          type: 'vector',
          url: this.configLayers.deelgebied_vlak.url,
        })
        this.activeSources.push('deelgebied_vlak')

        this.$store.map.addLayer({
          'id': 'deelgebied_vlak',
          'type': 'fill',
          'source': 'deelgebied_vlak',
          'source-layer': this.configLayers.deelgebied_vlak.source,
          'minzoom': 0,
          'paint': {
            'fill-color': '#EDCF98',
            'fill-opacity': [
              'case',
              ['boolean', ['feature-state', 'hover'], false],
              0.7,
              0,
            ],
          },
          'layout': {
            ...this.defaultLayout,
          },
        }, this.labelLayer)
        this.activeLayers.push('deelgebied_vlak')

        this.$store.map.addLayer({
          'id': 'deelgebied_vlak_text',
          'type': 'symbol',
          'source': 'deelgebied_vlak',
          'source-layer': this.configLayers.deelgebied_vlak.source,
          'paint': {
            'text-color': '#6E5425',
            'text-opacity': [
              'case',
              ['boolean', ['feature-state', 'hover'], false],
              0.9,
              0,
            ],
          },
          'layout': {
            ...this.defaultLayout,
            'text-field': ['get', 'label'],
            'symbol-placement': 'point',
            'text-allow-overlap': true,
          },
        }, this.labelLayer)
        this.activeLayers.push('deelgebied_vlak_text')

        /* When the user hovers over a subarea plane, highlight it and display the label */
        let hoveredStateId = null
        let sourceLayer = null

        this.$store.map.on('mousemove', 'deelgebied_vlak', (e) => {
          if (! e.features.length) return

          sourceLayer = e.features[0].layer['source-layer']

          /* clear previously hovered states */
          if (hoveredStateId !== null) {
            this.$store.map.setFeatureState(
                { source: 'deelgebied_vlak', sourceLayer: sourceLayer, id: hoveredStateId },
                { test: 'string', hover: false },
            )
          }

          hoveredStateId = e.features[0].id

          this.$store.map.setFeatureState(
              { source: 'deelgebied_vlak', sourceLayer: sourceLayer, id: hoveredStateId },
              { hover: true },
          )
        })

        this.$store.map.on('mouseleave', 'deelgebied_vlak', () => {
          if (hoveredStateId !== null) {
            this.$store.map.setFeatureState(
                { source: 'deelgebied_vlak', sourceLayer: sourceLayer, id: hoveredStateId },
                { hover: false },
            )
          }
          hoveredStateId = null
        })
      }

      // deelgebied lijnen
      if (this.isLayerActive({ name: 'deelgebied_lijn' })) {
        this.$store.map.addSource('deelgebied_lijn', {
          type: 'vector',
          url: this.configLayers.deelgebied_lijn.url,
        })
        this.activeSources.push('deelgebied_lijn')

        this.$store.map.addLayer({
          'id': 'deelgebied_lijn',
          'type': 'line',
          'source': 'deelgebied_lijn',
          'source-layer': this.configLayers.deelgebied_lijn.source,
          'paint': {
            'line-color': '#6E6046',
            'line-width': 1,
            'line-dasharray': [3, 1.5],
          },
          'layout': {
            ...this.defaultLayout,
          },
        }, this.labelLayer)
        this.activeLayers.push('deelgebied_lijn')
      }

      // snellaadzoekgebieden vlakken
      if (this.isLayerActive({ name: 'snellaadzoekgebieden' })) {
        this.$store.map.addSource('snellaadzoekgebieden', {
          type: 'vector',
          url: this.configLayers.snellaadzoekgebieden.url,
        })
        this.activeSources.push('snellaadzoekgebieden')

        this.$store.map.addLayer({
          'id': 'snellaadzoekgebieden',
          'type': 'fill',
          'source': 'snellaadzoekgebieden',
          'source-layer': this.configLayers.snellaadzoekgebieden.source,
          'minzoom': 0,
          'paint': {
            'fill-color': '#EDCF98',
            'fill-outline-color': '#cf5c1e',
            'fill-opacity': 0.4,
          },
          'layout': {
            ...this.defaultLayout,
          },
        }, this.labelLayer)
        this.activeLayers.push('snellaadzoekgebieden')
      }

      // Nieuwbouw locaties
      if (this.isLayerActive({ name: 'nieuwbouw' })) {
        this.$store.map.addSource('nieuwbouw', {
          type: 'vector',
          url: this.configLayers.nieuwbouw.url,
        })
        this.activeSources.push('nieuwbouw')

        this.$store.map.addLayer({
          'id': 'nieuwbouw',
          'type': 'fill',
          'source': 'nieuwbouw',
          'source-layer': this.configLayers.nieuwbouw.source,
          'minzoom': 12,
          'paint': {
            'fill-color': '#4264fb', // "hsl(52, 73%, 73%)",
            'fill-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              12,
              0,
              13,
              0.70,
            ],
            'fill-outline-color': '#314ccd',
          },
          'layout': {
            ...this.defaultLayout,
          },
        }, this.labelLayer)
        this.activeLayers.push('nieuwbouw')
      }

      // monuments locaties
      if (this.isLayerActive({ name: 'monuments' })) {
        this.$store.map.addSource('monuments', {
          type: 'vector',
          url: this.configLayers.monuments.url,
        })
        this.activeSources.push('monuments')

        this.$store.map.addLayer({
          'id': 'monuments',
          'type': 'fill',
          'source': 'monuments',
          'source-layer': this.configLayers.monuments.source,
          'minzoom': 12,
          'paint': {
            'fill-color': '#c41ee1', // "hsl(52, 73%, 73%)",
            'fill-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              12,
              0,
              13,
              0.70,
            ],
            'fill-outline-color': '#ac48b7',
          },
          'layout': {
            ...this.defaultLayout,
          },
        }, this.labelLayer)
        this.activeLayers.push('monuments')
      }

      // Lichtnet
      if (
        ! this.DeploymentLayerName({ layer: 'ls_kabels' }) &&
        this.isLayerActive({ name: 'ls' })
      ) {
        this.$store.map.addSource('ls', {
          type: 'vector',
          url: this.configLayers.ls.url,
        })
        this.activeSources.push('ls')

        this.$store.map.addLayer({
          'id': 'ls',
          'type': 'line',
          'source': 'ls',
          'source-layer': this.configLayers.ls.source,
          'minzoom': 13,
          'paint': {
            'line-color': 'hsl(144, 79%, 57%)', //"hsla(23,100%, 62%, 0.7)", // "hsl(52, 73%, 73%)",
            'line-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              13,
              0,
              14,
              0.90,
            ],
          },
          'layout': {
            ...this.defaultLayout,
          },
        }, this.labelLayer)
        this.activeLayers.push('ls')
      }

      // Lichtnet - Middenspanning
      if (this.isLayerActive({ name: 'ms' })) {
        this.$store.map.addSource('ms', {
          type: 'vector',
          url: this.configLayers.ms.url,
        })
        this.activeSources.push('ms')

        this.$store.map.addLayer({
          'id': 'ms',
          'type': 'line',
          'source': 'ms',
          'source-layer': this.configLayers.ms.source,
          'minzoom': 13,
          'paint': {
            'line-color': 'hsl(144, 66%, 37%)', //"hsla(23,100%, 62%, 0.7)", // "hsl(52, 73%, 73%)",
            'line-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              13,
              0,
              14,
              0.90,
            ],
          },
          'layout': {
            ...this.defaultLayout,
          },
        }, this.labelLayer)
        this.activeLayers.push('ms')
      }


      // N Wegen
      if (this.isLayerActive({ name: 'nwegen' })) {
        this.$store.map.addSource('nwegen', {
          type: 'vector',
          url: this.configLayers.nwegen.url,
        })
        this.activeSources.push('nwegen')

        this.$store.map.addLayer({
          'id': 'nwegen',
          'type': 'line',
          'source': 'nwegen',
          'source-layer': this.configLayers.nwegen.source,
          'minzoom': 10,
          'paint': {
            'line-color': 'hsl(360, 92%, 77%)', //"hsla(23,100%, 62%, 0.7)", // "hsl(52, 73%, 73%)",
            'line-width': 5,
            'line-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              10,
              0,
              10.3,
              0.90,
            ],
          },
          'layout': {
            ...this.defaultLayout,
          },
        }, this.labelLayer)
        this.activeLayers.push('nwegen')
      }


      // Parkeer druk
      if (this.isLayerActive({ name: 'parkeerdruk' })) {
        this.$store.map.addSource('parkeerdruk', {
          type: 'vector',
          url: this.configLayers.parkeerdruk.url,
        })
        this.activeSources.push('parkeerdruk')

        this.$store.map.addLayer({
          'id': 'parkeerdruk',
          'type': 'line',
          'source': 'parkeerdruk',
          'source-layer': this.configLayers.parkeerdruk.source,
          'minzoom': 13,
          'paint': {
            'line-color': '#FF6D6D', //"hsla(23,100%, 62%, 0.7)", // "hsl(52, 73%, 73%)",
            'line-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              13,
              0,
              14,
              0.90,
            ],
            'line-width': [
              'interpolate',
              ['linear'],
              ['zoom'],
              13,
              4,
              18,
              12,
            ],
          },
          'layout': {
            ...this.defaultLayout,
          },
        }, this.labelLayer)
        this.activeLayers.push('parkeerdruk')
      }

      // Openbare Verlichting
      if (this.isLayerActive({ name: 'ovkasten' })) {
        this.$store.map.addSource('ovkasten', {
          type: 'vector',
          url: this.configLayers.ovkasten.url,
        })
        this.activeSources.push('ovkasten')

        this.$store.map.addLayer({
          'id': 'ovkasten',
          'type': 'circle',
          'source': 'ovkasten',
          'source-layer': this.configLayers.ovkasten.source,
          'minzoom': 13,
          'paint': {
            'circle-radius': [
              'interpolate',
              ['linear'],
              ['zoom'],
              13,
              1,
              22,
              16,
            ],
            'circle-color': 'hsl(93, 28%, 43%)',
            'circle-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              13,
              0,
              14,
              0.72,
            ],
            'circle-stroke-width': 0,
          },
          'layout': {
            ...this.defaultLayout,
          },
        }, this.labelLayer)
        this.activeLayers.push('ovkasten')

        this.$store.map.addLayer({
          'id': 'ovkasten-labels',
          'type': 'symbol',
          'source': 'ovkasten',
          'source-layer': this.configLayers.ovkasten.source,
          'layout': {
            ...this.defaultLayout,
            'text-field': ['get', 'Text'],
            'text-anchor': 'top',
            'text-radial-offset': 0.5,
            'text-justify': 'auto',
            'text-size': 12,
          },
          'paint': {
            'text-color': '#6b6b6b',
            'text-translate': [
              'interpolate',
              ['linear'],
              ['zoom'],
              13,
              ['literal', [0, 4]],
              22,
              ['literal', [0, 16]],
            ],
            'text-halo-color': 'rgb(255,255,255)',
            'text-halo-width': 1,
            'text-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              15,
              0,
              16,
              0.72,
            ],
          },
        })
        this.activeLayers.push('ovkasten-labels')
      }

      // Parking Spaces
      if (this.isLayerActive({ name: 'parking' })) {
        this.$store.map.addSource('parking', {
          type: 'vector',
          url: this.configLayers.parking.url,
        })
        this.activeSources.push('parking')

        this.$store.map.addLayer({
          'id': 'parking',
          'type': 'fill',
          'source': 'parking',
          'source-layer': this.configLayers.parking.source,
          'minzoom': 13,
          'paint': {
            'fill-color': 'hsla(23,100%, 62%, 0.7)', // "hsl(52, 73%, 73%)",
            'fill-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              13,
              0,
              14,
              0.90,
            ],
          },
          'layout': {
            ...this.defaultLayout,
          },
        }, this.labelLayer)
        this.activeLayers.push('parking')
      }

      // Permit areas Amsterdam
      if (this.isLayerActive({ name: 'nl_g_0363_vergunningsgebieden' })) {
        this.$store.map.addSource('nl_g_0363_vergunningsgebieden', {
          type: 'vector',
          url: this.configLayers.nl_g_0363_vergunningsgebieden.url,
        })
        this.activeSources.push('nl_g_0363_vergunningsgebieden')

        this.$store.map.addLayer({
          'id': 'nl_g_0363_vergunningsgebieden',
          'type': 'fill',
          'source': 'nl_g_0363_vergunningsgebieden',
          'source-layer': this.configLayers.nl_g_0363_vergunningsgebieden.source,
          'minzoom': 12,
          'paint': {
            'fill-color': '#8bc34a',
            'fill-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              13,
              0,
              14,
              0.20,
            ],
          },
          'layout': {
            ...this.defaultLayout,
          },
        }, this.labelLayer)
        this.activeLayers.push('nl_g_0363_vergunningsgebieden')

        this.$store.map.addLayer({
          'id': 'nl_g_0363_vergunningsgebieden_line',
          'type': 'line',
          'source': 'nl_g_0363_vergunningsgebieden',
          'source-layer': this.configLayers.nl_g_0363_vergunningsgebieden.source,
          'minzoom': 13,
          'paint': {
            'line-color': '#748632',
            'line-width': 3,
          },
          'layout': {
            ...this.defaultLayout,
          },
        }, this.labelLayer)
        this.activeLayers.push('nl_g_0363_vergunningsgebieden_line')

        this.$store.map.addLayer({
          'id': 'nl_g_0363_vergunningsgebieden_text',
          'type': 'symbol',
          'source': 'nl_g_0363_vergunningsgebieden',
          'source-layer': this.configLayers.nl_g_0363_vergunningsgebieden.source,
          'minzoom': 13,
          'paint': {
            'text-color': '#748632',
          },
          'layout': {
            ...this.defaultLayout,
            'text-justify': 'auto',
            'text-field': ['get', 'OBJECTID'],
            'symbol-placement': 'point',
            'text-allow-overlap': true,
            'text-size': 20,
          },
        }, this.labelLayer)
        this.activeLayers.push('nl_g_0363_vergunningsgebieden_text')
      }

      // Parking lot Amsterdam
      if (this.isLayerActive({ name: 'parkinglot' })) {
        if (!this.$store.map.getSource('parkinglot')) {
          this.$store.map.addSource('parkinglot', {
            type: 'vector',
            url: this.configLayers.parkinglot.url,
          })
          this.activeSources.push('parkinglot')
        }

        if (!this.$store.map.getLayer('parkinglot')) {
          this.$store.map.addLayer({
            'id': 'parkinglot',
            'type': 'fill',
            'source': 'parkinglot',
            'source-layer': this.configLayers.parkinglot.source,
            'minzoom': 13,
            'paint': {
              'fill-outline-color': '#484896',
              'fill-color': 'hsla(230,100%, 62%, 0.4)', // todo:: add style conditions, share them with the watcher for default color, so that it has the correct color also on load
              'fill-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                13,
                0,
                14,
                0.90,
              ],
            },
            'layout': {
              ...this.defaultLayout,
            },
          }, this.labelLayer)
          this.activeLayers.push('parkinglot')
        }
        if (!this.$store.map.getLayer('parkinglot_text')) {
          this.$store.map.addLayer({
            'id': 'parkinglot_text',
            'type': 'symbol',
            'source': 'parkinglot',
            'source-layer': this.configLayers.parkinglot.source,
            'minzoom': 20,
            'paint': {
              'text-color': 'black',
            },
            'layout': {
              ...this.defaultLayout,
              'text-justify': 'auto',
              'text-field': ['get', 'id'],
              'symbol-placement': 'point',
              'text-allow-overlap': true,
              'text-size': 16,
              'text-anchor': 'top',
            },
          })
          this.activeLayers.push('parkinglot_text')
        }

        if (!this.$store.map.getLayer('parkinglot_text_type')) {
          this.$store.map.addLayer({
            'id': 'parkinglot_text_type',
            'type': 'symbol',
            'source': 'parkinglot',
            'source-layer': this.configLayers.parkinglot.source,
            'minzoom': 20,
            'paint': {
              'text-color': 'black',
            },
            'layout': {
              ...this.defaultLayout,
              'text-justify': 'auto',
              'text-field': ['get', 'bord'],
              'symbol-placement': 'point',
              'text-allow-overlap': true,
              'text-size': 16,
              'text-anchor': 'bottom',
            },
          })
          this.activeLayers.push('parkinglot_text_type')
        }

        this.setParkingLotsStyle()

        // Add click event on parkinglot and pass the ID to PlanMode popup (only Amsterdam)
        this.$store.map.on('click', 'parkinglot', this.handleParkinglot)
      } else {
        this.$store.map.off('click', 'parkinglot', this.handleParkinglot)
      }

      // Trees on top
      if (this.isLayerActive({ name: 'trees_zone' })) {
        this.$store.map.addSource('trees_zone_z', {
          type: 'vector',
          url: this.configLayers.trees_zone_z.url,
        })
        this.activeSources.push('trees_zone_z')

        this.$store.map.addLayer({
          'id': 'trees_zone_z',
          'type': 'fill',
          'source': 'trees_zone_z',
          'source-layer': this.configLayers.trees_zone_z.source,
          'minzoom': 12,
          'paint': {
            'fill-color': 'hsla(93, 28%, 43%, 0.5)', // "hsl(52, 73%, 73%)",
            'fill-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              12,
              0,
              13,
              0.90,
            ],
          },
          'layout': {
            ...this.defaultLayout,
          },
        }, this.labelLayer)
        this.activeLayers.push('trees_zone_z')

        this.$store.map.addSource('trees_zone', {
          'type': 'vector',
          'url': this.configLayers.trees_zone.url,
        })
        this.activeSources.push('trees_zone')

        this.$store.map.addLayer({
          'id': 'trees_zone',
          'type': 'circle',
          'source': 'trees_zone',
          'source-layer': this.configLayers.trees_zone.source,
          'minzoom': 13,
          'paint': {
            'circle-radius': [
              'interpolate',
              ['linear'],
              ['zoom'],
              13,
              1,
              22,
              10,
            ],
            'circle-color': 'hsl(93, 28%, 43%)',
            'circle-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              13,
              0,
              14,
              0.40,
            ],
            'circle-stroke-width': 0,
          },
          'layout': {
            ...this.defaultLayout,
          },
        }, this.labelLayer)
        this.activeLayers.push('trees_zone')

        // To avoid breaking the activation process of the legend toggles in case `trees` has also been configured as layer
        if (this.isLayerActive({ name: 'trees' })) {
          this.activeLayers.push('trees')
        }
      }
      else if (this.isLayerActive({ name: 'trees' })) {
        this.$store.map.addSource('trees', {
          'type': 'vector',
          'url': this.configLayers.trees.url,
        })
        this.activeSources.push('trees')

        this.$store.map.addLayer({
          'id': 'trees',
          'type': 'circle',
          'source': 'trees',
          'source-layer': this.configLayers.trees.source,
          'minzoom': 13,
          'paint': {
            'circle-radius': [
              'interpolate',
              ['linear'],
              ['zoom'],
              13,
              1,
              22,
              10,
            ],
            'circle-color': 'hsl(93, 28%, 43%)',
            'circle-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              13,
              0,
              14,
              0.40,
            ],
            'circle-stroke-width': 0,
          },
          'layout': {
            ...this.defaultLayout,
          },
        }, this.labelLayer)
        this.activeLayers.push('trees')
      }

      if (this.isLayerActive({ name: 'licht_infrastructuur' })) {
        this.$store.map.addSource('licht_infrastructuur', {
          'type': 'vector',
          'url': this.configLayers.licht_infrastructuur.url,
        })
        this.activeSources.push('licht_infrastructuur')

        this.$store.map.addLayer({
          'id': 'licht_infrastructuur',
          'type': 'circle',
          'source': 'licht_infrastructuur',
          'source-layer': this.configLayers.licht_infrastructuur.source,
          'minzoom': 13,
          'paint': {
            'circle-radius': [
              'interpolate',
              ['linear'],
              ['zoom'],
              13,
              1,
              22,
              10,
            ],
            'circle-color': 'hsl(50, 100%, 50%)',
            'circle-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              13,
              0,
              14,
              0.85,
            ],
            'circle-stroke-width': 1,
            'circle-stroke-color': 'gray',
            'circle-stroke-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              13,
              0,
              14,
              0.85,
            ],
          },
          'layout': {
            ...this.defaultLayout,
          },
        }, this.labelLayer)
        this.activeLayers.push('licht_infrastructuur')
      }

      // Lichtnet
      if (this.isLayerActive({ name: 'lichtnet' })) {
        this.$store.map.addSource('lichtnet', {
          type: 'vector',
          url: this.configLayers.lichtnet.url,
        })
        this.activeSources.push('lichtnet')

        this.$store.map.addLayer({
          'id': 'lichtnet',
          'type': 'line',
          'source': 'lichtnet',
          'source-layer': this.configLayers.lichtnet.source,
          'minzoom': 13,
          'paint': {
            'line-color': 'hsl(50, 100% ,50%)',
            'line-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              13,
              0,
              14,
              0.90,
            ],
          },
          'layout': {
            ...this.defaultLayout,
          },
        }, this.labelLayer)
        this.activeLayers.push('lichtnet')
      }

      if (this.isLayerActive({ name: 'straatmeubilair' })) {
        this.$store.map.addSource('straatmeubilair', {
          'type': 'vector',
          'url': this.configLayers.straatmeubilair.url,
        })
        this.activeSources.push('straatmeubilair')

        this.$store.map.addLayer({
          'id': 'straatmeubilair',
          'type': 'circle',
          'source': 'straatmeubilair',
          'source-layer': this.configLayers.straatmeubilair.source,
          'minzoom': 13,
          'paint': {
            'circle-radius': [
              'interpolate',
              ['linear'],
              ['zoom'],
              13,
              1,
              22,
              10,
            ],
            'circle-color': 'hsl(224, 72%, 28%)',
            'circle-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              13,
              0,
              14,
              0.85,
            ],
            'circle-stroke-width': 0,
          },
          'layout': {
            ...this.defaultLayout,
          },
        }, this.labelLayer)
        this.activeLayers.push('straatmeubilair')
      }

      // visitorlocations on top - there are a lot
      if (this.isLayerActive({ name: 'visitorlocations' })) {
        this.$store.map.addSource('visitorlocations', {
          'type': 'vector',
          'url': this.configLayers.visitorlocations.url,
        })
        this.activeSources.push('visitorlocations')

        this.$store.map.addLayer({
          'id': 'visitorlocations',
          'type': 'circle',
          'source': 'visitorlocations',
          'source-layer': this.configLayers.visitorlocations.source,
          'minzoom': 13,
          'paint': {
            'circle-radius': [
              'interpolate',
              ['linear'],
              ['zoom'],
              13,
              1,
              22,
              10,
            ],
            'circle-color': 'hsl(0, 98%, 69%)',
            'circle-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              13,
              0,
              14,
              0.85,
            ],
            'circle-stroke-width': 0,
          },
          'layout': {
            ...this.defaultLayout,
          },
        }, this.labelLayer)
        this.activeLayers.push('visitorlocations')
      }

      // worklocations on top - there are a lot
      if (this.isLayerActive({ name: 'worklocations' })) {
        this.$store.map.addSource('worklocations', {
          'type': 'vector',
          'url': this.configLayers.worklocations.url,
        })
        this.activeSources.push('worklocations')

        this.$store.map.addLayer({
          'id': 'worklocations',
          'type': 'circle',
          'source': 'worklocations',
          'source-layer': this.configLayers.worklocations.source,
          'minzoom': 13,
          'paint': {
            'circle-radius': [
              'interpolate',
              ['linear'],
              ['zoom'],
              13,
              1,
              22,
              10,
            ],
            'circle-color': '#ff00f4',
            'circle-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              13,
              0,
              14,
              0.85,
            ],
            'circle-stroke-width': 0,
          },
          'layout': {
            ...this.defaultLayout,
          },
        }, this.labelLayer)
        this.activeLayers.push('worklocations')
      }

      // Park&Ride
      if (
        ! this.DeploymentLayerName({ layer: 'park_n_ride' }) &&
        this.isLayerActive({ name: 'pplusr' })
      ) {
        this.$store.map.addSource('pplusr', {
          'type': 'vector',
          'url': this.configLayers.pplusr.url,
        })
        this.activeSources.push('pplusr')

        const addPplusrLayer = () => {
          if (this.$store.map.getLayer('pplusr')) return

          this.$store.map.addLayer({
            'id': 'pplusr',
            'type': 'symbol',
            'source': 'pplusr',
            'source-layer': this.configLayers.pplusr.source,
            'minzoom': 11,
            'layout': {
              ...this.defaultLayout,
              'symbol-placement': 'point',
              'symbol-z-order': 'source',
              'icon-allow-overlap': true,
              'icon-image': 'parkandride',  // "charging-station-15",
              'icon-size': [ // transition based on zoom
                'interpolate',
                ['linear'],
                ['zoom'],
                11,
                0.05,
                18,
                0.2,
              ],
            },
            'paint': {
              // "icon-color": "",
              'icon-opacity': [ // transition based on zoom
                'interpolate',
                ['linear'],
                ['zoom'],
                10,
                0,
                10.2,
                0.8,
              ],
            },
          }, this.labelLayer)
          this.activeLayers.push('pplusr')
        }
        if (!this.$store.map.hasImage('parkandride')) {
          try {
            this.$store.map.loadImage(
              require('@/assets/image/legend/parkandride.png'),
              (err, image) => {
                if (err) return

                if (! this.$store.map.hasImage('parkandride')) {
                  this.$store.map.addImage('parkandride', image)
                  addPplusrLayer()
                }
            })
          } catch (e) {
            //
          }
        } else {
          addPplusrLayer()
        }
      }

      // Fuel on top
      if (
        ! this.DeploymentLayerName({ layer: 'fossilfuel_stations' }) &&
        this.isLayerActive({ name: 'fuelstations' })
      ) {

        this.$store.map.addSource('fuelstations', {
          'type': 'vector',
          'url': this.configLayers.fuelstations.url,
        })
        this.activeSources.push('fuelstations')

        const addFuelStationLayer = () => {
          if (this.$store.map.getLayer('fuelstations')) return

          this.$store.map.addLayer({
            'id': 'fuelstations',
            'type': 'symbol',
            'source': 'fuelstations',
            'source-layer': this.configLayers.fuelstations.source,
            'minzoom': 11,
            'layout': {
              ...this.defaultLayout,
              'symbol-placement': 'point',
              'symbol-z-order': 'source',
              'icon-allow-overlap': true,
              'icon-image': 'fuelpump',
              'icon-size': [ // transition based on zoom
                'interpolate',
                ['linear'],
                ['zoom'],
                11,
                0.05,
                18,
                0.2,
              ],
            },
            'paint': {
              // "icon-color": "",
              'icon-opacity': [ // transition based on zoom
                'interpolate',
                ['linear'],
                ['zoom'],
                11,
                0,
                11.2,
                0.8,
              ],
            },
          }, this.labelLayer)
          this.activeLayers.push('fuelstations')
        }

        if (!this.$store.map.hasImage('fuelpump')) {
          try {
            this.$store.map.loadImage(
              require('@/assets/image/legend/fuelpump.png'),
              (err, image) => {
                if (err) return

                if (! this.$store.map.hasImage('fuelpump')) {
                  this.$store.map.addImage('fuelpump', image)
                  addFuelStationLayer()
                }
            })
          } catch (e) {
            //
          }
        } else {
          addFuelStationLayer()
        }
      }

      // CNG / LNG on top
      if (this.isLayerActive({ name: 'lngcng' })) {

        this.$store.map.addSource('lngcng', {
          'type': 'vector',
          'url': this.configLayers.lngcng.url,
        })
        this.activeSources.push('lngcng')

        const addLNGCNGStationLayer = () => {
          if (this.$store.map.getLayer('lngcng')) return

          this.$store.map.addLayer({
            'id': 'lngcng',
            'type': 'symbol',
            'source': 'lngcng',
            'source-layer': this.configLayers.lngcng.source,
            'minzoom': 11,
            'layout': {
              ...this.defaultLayout,
              'symbol-placement': 'point',
              'symbol-z-order': 'source',
              'icon-allow-overlap': true,
              'icon-image': 'lngcng',
              'icon-size': [ // transition based on zoom
                'interpolate',
                ['linear'],
                ['zoom'],
                11,
                0.05,
                18,
                0.2,
              ],
            },
            'paint': {
              // "icon-color": "",
              'icon-opacity': [ // transition based on zoom
                'interpolate',
                ['linear'],
                ['zoom'],
                11,
                0,
                11.2,
                0.8,
              ],
            },
          }, this.labelLayer)
          this.activeLayers.push('lngcng')
        }

        if (!this.$store.map.hasImage('lngcng')) {
          try {
            this.$store.map.loadImage(
              require('@/assets/image/legend/lngcng.png'),
              (err, image) => {
                if (err) return

                if (! this.$store.map.hasImage('lngcng')) {
                  this.$store.map.addImage('lngcng', image)
                  addLNGCNGStationLayer()
                }
            })
          } catch (e) {
            //
          }
        } else {
          addLNGCNGStationLayer()
        }
      }

      // almere leidingen
      if (this.isLayerActive({ name: 'almere_leidingen' })) {
        this.$store.map.addSource('almere_leidingen', {
          type: 'vector',
          url: this.configLayers.almere_leidingen.url,
        })
        this.activeSources.push('almere_leidingen')

        this.$store.map.addLayer({
          'id': 'almere_leidingen',
          'type': 'fill',
          'source': 'almere_leidingen',
          'source-layer': this.configLayers.almere_leidingen.source,
          'paint': {
            'fill-color': '#1b4b0f',
            'fill-opacity': .35,
          },
          'layout': {
            ...this.defaultLayout,
          },
        }, this.labelLayer)
        this.activeLayers.push('almere_leidingen')
      }

      // milieuprocedure
      if (this.isLayerActive({ name: 'milieuprocedure' })) {
        this.$store.map.addSource('milieuprocedure', {
          type: 'vector',
          url: this.configLayers.milieuprocedure.url,
        })
        this.activeSources.push('milieuprocedure')

        let styleCondition = [
          'case',
          // AUTOMATISCH
          [
            '==',
            [
              'get',
              'methode',
            ],
            'AUTOMATISCH',
          ],
          'rgb(0,80,0)',
          // HANDMATIG
          [
            '==',
            [
              'get',
              'methode',
            ],
            'HANDMATIG',
          ],
          'rgb(0,0,50)',
          'rgb(0,0,0)',
        ]

        this.$store.map.addLayer({
          'id': 'milieuprocedure',
          'type': 'fill',
          'source': 'milieuprocedure',
          'source-layer': this.configLayers.milieuprocedure.source,
          'paint': {
            'fill-color': styleCondition,
            'fill-opacity': .3,
          },
          'layout': {
            ...this.defaultLayout,
          },
        }, this.labelLayer)
        this.activeLayers.push('milieuprocedure')
      }

      // Stortingskaart bodem
      if (this.isLayerActive({ name: 'stortingskaart_bodem' })) {
        this.$store.map.addSource('stortingskaart_bodem', {
          type: 'vector',
          url: this.configLayers.stortingskaart_bodem.url,
        })
        this.activeSources.push('stortingskaart_bodem')

        let styleCondition = [
          'case',
          // Basishygiëne
          [
            '==',
            [
              'get',
              'code_crow',
            ],
            'Basishygiëne',
          ],
          '#808000',
          // voorzorgsmaatregelen
          [
            '==',
            [
              'get',
              'code_crow',
            ],
            'voorzorgsmaatregelen',
          ],
          '#800000',
          'rgb(0,0,0)',
        ]

        this.$store.map.addLayer({
          'id': 'stortingskaart_bodem',
          'type': 'fill',
          'source': 'stortingskaart_bodem',
          'source-layer': this.configLayers.stortingskaart_bodem.source,
          'paint': {
            'fill-color': styleCondition,
            'fill-opacity': .3,
          },
          'layout': {
            ...this.defaultLayout,
          },
        }, this.labelLayer)
        this.activeLayers.push('stortingskaart_bodem')
      }

      /* end */
    },
    isLayerActive({ name }) {
      return this.configLayers[name] && ['primary', 'secondary'].includes(this.configLayers[name].status)
    },

    /**
     * Go through all registered layers and source to clean them out
     */
    removeLayers() {
      this.setLoaded({
        loaded: false,
      })

      // Remove all registered layers
      this.activeLayers.forEach(layer => {
        if (this.$store.map.getLayer(layer)) {
          this.$store.map.removeLayer(layer)
        }
      })
      this.activeLayers = []

      // Remove all registered sources
      this.activeSources.forEach(source => {
        if (this.$store.map.getSource(source)) {
          this.$store.map.removeSource(source)
        }
      })
      this.activeSources = []
    },
    // Add all pipes layers from pipesLayers object
    addPipesLayer ({ layer }) {
      if (this.isLayerActive({ name: layer.name })) {
        this.$store.map.addSource(layer.name, {
          type: 'vector',
          url: this.configLayers[layer.name].url,
        })
        this.activeSources.push(layer.name)

        this.$store.map.addLayer({
          'id': layer.name,
          'type': 'line',
          'source': layer.name,
          'source-layer': this.configLayers[layer.name].source,
          'paint': {
            'line-width': 2,
            'line-color': layer.color,
            'line-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              12,
              0,
              13,
              1,
            ],
          },
          'layout': {
            ...this.defaultLayout,
          },
        }, this.labelLayer)
        this.activeLayers.push(layer.name)
      }
    },
  },
}
</script>
