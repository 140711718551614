<template>
  <div v-if="municipalityTenant && isWorkflowAdmin">
    <div>
      <b-alert
        :show="!hasStatusToStartProcess || !isCorrectCpoSelected"
        variant="primary"
        class="mt-3 w-100 create-process"
      >
        <ul class="p-1">
          {{ $t(`${translationPath}.requirements`) }}
          <li
            v-if="!hasStatusToStartProcess"
            class="mt-2"
          >
            {{ $t(`${translationPath}.statusTypes`) }}
            <ul class="pl-4">
              <li class="mt-1">
                {{ $t('groupLabels.suggestion') }}
                <img
                  width="15"
                  :src="image({ name: suggestion })"
                  class="mx-1"
                >
              </li>
              <li class="mt-1">
                {{ $t('groupLabels.definitive') }}
                <img
                  width="15"
                  :src="image({ name: definitive })"
                  class="mx-1"
                >
              </li>
            </ul>
          </li>
          <li
            v-if="!isCorrectCpoSelected"
            class="mt-1"
          >
            {{ $t(`${translationPath}.selectedCpo`) }}
          </li>
        </ul>
      </b-alert>
      <b-button
        :disabled="!isInProcess && (!hasStatusToStartProcess || !isCorrectCpoSelected)"
        variant="primary"
        class="mt-3 w-100 create-process"
        @click="startWorkflowProcess"
      >
        <b-spinner
          v-if="isLoading"
          small
        />
        <span>{{ $t(`${translationPath}.createProcess`) }}</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import chargingpointDataMixin from '@/mixins/chargingpoint/chargingpointDataMixin'
import { getCpoById } from '@/data/cpos'
import { image } from '@/helpers/assets'

export default {
  name: 'CreateWorkflowProcess',
  mixins: [chargingpointDataMixin],
  data () {
    return {
      isLoading: false,
      hasWorkflowTenant: false,
      municipalityWorkflowTenants: {
        '0363': 'amsterdam',
      },
      isCorrectCpoSelected: false,
      translationPath: 'components.sidebar.Tabs.ProcessInfoTab',

      image,
      definitive: 'chargingpoint/png/definitive.png',
      suggestion: 'chargingpoint/png/suggestion.png',
    }
  },
  computed: {
    ...mapGetters('access', ['getActiveMunicipality']),
    ...mapGetters('config', ['config']),

    isInProcess() {
      return (
        !!this.chargingpoint.data?.workflowCaseRef ||
        !!this.chargingpoint.data?.externalProcessId
      )
    },
    workflowRoles () {
      return this.$auth.user['https://evmaps.nl/workflow'].roles
    },
    hasStatusToStartProcess () {
      return ['suggestion', 'definitive'].includes(this.chargingpoint.data.properties.status)
    },
    userIdentity () {
      return this.$auth.user['https://evmaps.nl/identity']?.uuid
    },
    municipalityTenant () {
      return this.municipalityWorkflowTenants[this.getActiveMunicipality]
    },
    isWorkflowAdmin () {
      const tenant = this.municipalityTenant || getCpoById(this.userIdentity)?.workflow?.owner
      const adminRole = this.workflowRoles.some(role => role.tenant === tenant && role.admin.some(adminRole => {
        return ['*', this.getActiveMunicipality].includes(adminRole)
      }))

      return tenant && adminRole
    },
    isAllowedToStartProcess () {
      return !this.isInProcess && (this.isWorkflowAdmin && this.hasStatusToStartProcess)
    },
  },
  watch: {
    chargingpoint: {
      immediate: true,
      deep: true,
      handler (cp) {
        if (this.isAllowedToStartProcess) {
          const [stakeholder] = cp.data.properties?.stakeholders || []
          this.isCorrectCpoSelected = stakeholder &&
                                      getCpoById(stakeholder.uuid)?.workflow?.tenant?.includes(this.municipalityTenant)
          this.hasWorkflowTenant = !!this.municipalityTenant ||
                                   (this.userIdentity === stakeholder?.uuid && getCpoById(this.userIdentity)?.workflow?.owner)
        }
      },
    },
  },
  methods: {
    async startWorkflowProcess () {
      this.isLoading = true
      const api = await fetch(`${process.env.VUE_APP_EVTOOLS_API_ENDPOINT}/process/realisation/`, {
        method: 'POST',
        headers: {
          'x-api-key': process.env.VUE_APP_EVTOOLS_APIKEY,
        },
        body: JSON.stringify({
          chargingpointUuid: this.chargingpoint.data.uuid,
          user: {
            name: `${this.$auth.user.name} (via Maps)`,
            id: this.$auth.user.sub || 'Unknown',
          },
        }),
      }).catch(err => console.log(err))

      if (api.ok) {
        this.isLoading = false
        const response = await api.json()
        const error = !response.data
        if (error) {
          const { message } = JSON.parse(response.body)
          return this.$emit('setAlert', { type: 'danger', message })
        }

        const chargingpoint = this.chargingpoint
        chargingpoint.data.workflowUuid = response.data.uuid
        chargingpoint.data.workflowCaseRef = response.data.case?.full

        this.$store.dispatch('planmode/addOrUpdateChargingPoint', { chargingpoint })
        this.isLoading = false
        this.$emit('setAlert', { type: 'success' })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.create-process {
  position: relative;
  .spinner-border {
    position: absolute;
    left: 1em;
    top: 0.6em;
  }
}
</style>
