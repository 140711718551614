import privilegesMixin from '@/mixins/common/privilegesMixin'

import { cpos, getCpoById } from '@/data/cpos'
import { mapGetters } from 'vuex'
import { STAKEHOLDER_TYPE } from '@/../shared/valueholders/stakeholder-types'

export default {
  mixins: [privilegesMixin],
  data() {
    return {
      fields: {
        cpo: {
          label: 'Charge Point Operator',
          value: '',
          type: 'select',
        },
      },
    }
  },
  computed: {
    ...mapGetters('deployment', ['DeploymentConfig']),
    ...mapGetters('currentUser', {
      currentUserRole: 'getCurrentUserRole',
    }),
    identity () {
      return this.$auth.user['https://evmaps.nl/identity']
    },
    isAllowedToEditCpo() {
      return this.superuser || this.admin
    },
    isUserCpo () {
      return this.currentUserRole === 'cpo'
    },
    cposByDeployment() {
      return cpos
        .filter(cpo => cpo.countries.includes(this.DeploymentConfig.country))
        .sort((a, b) => a.name.localeCompare(b.name))
    },
    cpoSelectOptions() {
      if (this.isUserCpo && this.identity?.category === 'cpo') {
        return this.cposByDeployment.map(cpo => {
          if (cpo.uuid === this.identity.uuid || cpo.uuid === this.currentCpo?.uuid) {
            return { value: cpo.uuid, text: cpo.name }
          }
        }).filter(Boolean)
      }

      return this.cposByDeployment.map(cpo => ({ value: cpo.uuid, text: cpo.name }))
    },
    currentCpo() {
      if (!this.chargingpoint) return
      if (this.stakeholders === undefined) {
        return false
      }
      return this.stakeholders.find(stakeholder => stakeholder.type === STAKEHOLDER_TYPE.CPO)
    },
  },
  created () {
    // Preselect CPO value if user role is CPO. //
    if (!this.fields.cpo.value && this.cpoSelectOptions.length === 1) {
      this.fields.cpo.value = this.cpoSelectOptions[0].value
    }
  },
  methods: {
    $_chargingpointCpoMixin_getCpoByFieldSelection() {
      const cpo = Object.assign({}, cpos.find(cpo => cpo.uuid === this.fields.cpo.value))
      delete cpo.workflow
      return cpo
    },
    $_chargingpointCpoMixin_getCpoById(uuid) {
      const cpo = Object.assign({}, getCpoById(cpos, uuid))
      delete cpo.workflow
      return cpo
    },
    $_chargingpointCpoMixin_removeOrUpdateCpoFromStakeholders() {
      // if there was a cpo already assigned, filter it out and add the selected one
      const stakeholders = this.stakeholders.filter(stakeholder => stakeholder.type !== STAKEHOLDER_TYPE.CPO)
      if (this.fields.cpo.value !== null) {
        const cpo = this.$_chargingpointCpoMixin_getCpoByFieldSelection()

        if (cpo) {
          stakeholders.push(cpo)
        }
      }

      return stakeholders
    },
  },
}
